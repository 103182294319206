import { TABLA } from '@/utils/consts'

export default {
  async selectAccionCorrectoraOt (Vue, idaccionCorrectoraOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.accion_correctora_ot)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot)
      )
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
      .innerJoin(
        tables.checklist_ot,
        tables.grupo_checklist_ot.idchecklist_ot.eq(tables.checklist_ot.idchecklist_ot)
      )
      .where(tables.accion_correctora_ot.idaccion_correctora_ot.eq(idaccionCorrectoraOt))
      .exec())[0]
  },
  async selectFicherosCount (Vue, idaccionCorrectoraOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select(Vue.$offline.db.fn.count(tables.fichero.idfichero).as('count'))
      .from(tables.fichero)
      .where(
        Vue.$offline.db.op.and(
          tables.fichero.idtabla.eq(TABLA.accion_correctora_ot.idtabla),
          tables.fichero.id.eq(idaccionCorrectoraOt),
        )
      )
      .exec())[0]
  },
  selectFicheroAccionCorrectora (Vue, idaccionCorrectoraOt) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.fichero)
      .innerJoin(
        tables.tfichero,
        tables.fichero.idtfichero.eq(tables.tfichero.idtfichero)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.fichero.id.eq(idaccionCorrectoraOt),
          tables.fichero.idtabla.eq(TABLA.accion_correctora_ot.idtabla),
        )
      )
      .exec()
  },
}
